<template>
  <div class="Work">
    <div class="work-top">
      <div class="work-top__header">
        <h1>Our Work</h1>
        <div class="work-top__line"></div>
      </div>
      <div class="work-top__images-container">
        <div class="grid-one">
          <!-- <img src="@/assets/home/rse.png" alt="RSE Phone" /> -->
        </div>
        <div class="grid-two">
          <!-- <img src="@/assets/home/apartments.jpg" alt="RSE Phone" /> -->
        </div>
        <div class="grid-three">
          <!-- <img src="@/assets/home/securitycamers.jpg" alt="RSE Phone" /> -->
        </div>
      </div>
    </div>

    <div class="morework">
      <div
        class="morework__div__background"
        v-for="(work, index) in works"
        :key="index"
      >
        <div class="image__container">
          <img
            :src="
              work.image != 'EMARRT.jpg'
                ? require(`@/assets/home/works/${work.image}`)
                : require('@/assets/home/EEMART.png')
            "
            :style="work.image == 'EMARRT.jpg' && { 'object-fit': 'cover' }"
          />
        </div>
        <div class="line__container"></div>
        <div
          class="text__container"
          :style="(index + 1) % 2 == 0 && { 'background-color': '#00b3a2' }"
        >
          <h3>{{ work.title }}</h3>
          <p>{{ work.description }}</p>
          <h6>
            <a :href="work.link" target="_blank">INITIATE PROJECT</a>
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LayoutDefault from "../layouts/LayoutDefault.vue";
import Work from "@/database/work.json";

export default {
  name: `Work`,
  components: {},
  data() {
    return {
      works: Work,
    };
  },
  created() {
    // this.$emit(`update:layout`, LayoutDefault);
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss">
.work-top {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  margin-top: 2rem;
  height: 50rem;
  @include respond(phone) {
    flex-direction: column-reverse;
    margin-top: 25rem;
  }

  &__header {
    height: 15%;
    @include respond(phone) {
      margin-top: 10rem;
    }
    h1 {
      text-transform: uppercase;
      font-size: 6rem;
      color: $color-white;
      font-weight: bolder;
      margin-bottom: 4rem;
    }
    .work-top__line {
      position: relative;
      width: 100%;
      height: 100%;
      &::before {
        content: "";
        height: 100%;
        background: $color-white;
        width: 0.5%;
        @include absCenter;
      }
    }
  }
  &__images-container {
    background: #00b3a2;
    padding: 3rem;
    width: 50%;
    height: fit-content;
    padding-bottom: 4rem;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 20rem 20rem;
    grid-template-areas:
      "Image1 Image3"
      "Image2 Image3";
    row-gap: 3rem;
    column-gap: 2rem;
    justify-items: center;
    padding-right: 5rem;
    // align-items: center;
    @include respond(phone) {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
    }
    .grid-one {
      grid-area: Image1;
      background: red;
      width: 100%;
      height: 100%;
      background-image: url("../assets/home/rse.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
      -webkit-box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
      -moz-box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
      @include respond(phone) {
        height: 50rem;
        background-position: top;
      }
    }
    .grid-two {
      grid-area: Image2;
      background: rebeccapurple;
      width: 100%;
      height: 100%;
      background-image: url("../assets/home/apartments.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
      -webkit-box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
      -moz-box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
      @include respond(phone) {
        height: 50rem;
        background-position: top;
      }
    }
    .grid-three {
      grid-area: Image3;
      background: blue;
      width: 100%;
      background-image: url("../assets/home/securitycamers.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
      -webkit-box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
      -moz-box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
      @include respond(phone) {
        display: none;
      }
    }
  }
}

.morework {
  margin-top: 100px;
  &__div__background {
    background: $color-white;
    width: 100%;
    height: 45rem;
    box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
    -webkit-box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
    -moz-box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
    display: flex;
    align-items: center;
    gap: 3rem;
    flex-direction: row;
    position: relative;
    padding: 3rem;
    margin-top: 10rem;
    @include respond(phone) {
      flex-direction: column;
      // display: none;
      height: fit-content;
    }
    .image__container {
      height: 100%;
      width: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      @include respond(phone) {
        height: 30rem;
        width: 100%;
      }
      & img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
    .text__container {
      width: 40%;
      height: 35rem;
      background: #eae321;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4.5rem;
      justify-content: center;
      box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
      -webkit-box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
      -moz-box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
      @include respond(phone) {
        width: 100%;
        height: 42rem;
        padding: 30px 0;
      }
      h3 {
        font-size: 2.5rem;
        font-weight: bolder;
      }
      h3,
      h6,
      p {
        width: 35rem;
        width: 35rem;
        @include respond(tab-port) {
          width: 28rem;
        }
        @include respond(phone) {
          width: 25rem;
        }
      }
      p {
        font-size: 1.4rem;
      }
      h6 {
        font-size: 1.1rem;
        a {
          text-decoration: none;
          color: rgb(83, 83, 83);
        }
      }
    }

    .line__container {
      width: 5%;
      height: 100%;
      position: relative;
      @include respond(phone) {
        width: 1%;
        height: 0.3rem;
      }
      &::before {
        content: "";
        height: 100%;
        width: 4%;
        background: #3d3d80;
        position: absolute;
        @include respond(phone) {
          width: 100%;
        }
      }
    }
  }
}
</style>
